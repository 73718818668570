.notFound{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    min-height: 80vh;
    height: 100%;
    width: 100%;
}

.notFound_icon {
    font-size: 8rem
}