.footer {
  height: 1.2em;
  padding: 1% 1%;
  margin-bottom: 0;
  margin-top: 24px;
  bottom: 0;
  background: linear-gradient(
    135deg,
    #ffffff 0%,
    #f6f6f6 26%,
    #f6f6f6 26%,
    #f1f1f1 100%,
    #e8e8e8 100%
  );
  border-top: 1px solid rgba(31, 45, 61, 0.15);
  filter: drop-shadow(1px -1px 1px rgba(0, 0, 0, 0.7));
}

.footer p {
  max-width: 1200px;
  margin: auto;
}

@media screen and (max-width: 1023px) and (min-width: 100px) {
  .footer {
    height: 25px;
    font-size: smaller;
  }
}
