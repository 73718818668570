.machineDes{
    position: relative;
    max-width: 1200px;
    display: flex;
    margin: auto;
    margin-top: 30px;
    margin-bottom: 20px;
    padding: 0.5% 1%;
    border-radius: 10px;
    border: 4px solid #cedce7;
    z-index: -1;
}

.machineTobe{
    background: #fff; 
    padding: 5px;
    border-radius: 50%;
    border: 4px solid #cedce7;
    position: absolute;
    display:flex;
    align-items: center;
    top: -45%;
    left: -7%;
    animation: spin 10s linear infinite;
}

.machineTobe img{
    width: 85px;
}

.content{
    width: 100%;
    max-width: 1200px;
    margin: auto;
    display:flex;
    margin-left: 8px;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.machineFeatures{
    width: 100%;
    display:flex;
    align-items: center;
    margin: 0 ;
}

.machineFeatureItem{
    display:flex;
    align-items: center;
    margin-left: 28px;
}

.machineFeatureItem i{
    display:flex;
    color: orangered;
    font-size: 1.5rem;
    margin-right: 10px;
}

.machineFeatures h5{
    margin: 0 ;

}
