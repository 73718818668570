.root {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    border-top: 1px solid #000;
    padding: 8px 20px;
}

.mainContainer {
    display: flex;
    margin-left: 10px;
    align-items: center;
}

.btnContainer {
    display: flex;
    margin-left: 5px;
}

.btn {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: inherit;
    border: solid 0.5px #cecece;
    padding: 8px;
    border-radius: 4px
}

.btn:hover {
    background-color: #cecece;
    border: solid 0.5px #cecece;
    border-radius: 4px
}