.backDrop{
    display: flex;
    justify-content:center ;
    align-items: center;
    width:100vh;
    height: 100vh;
}

.loaderTitle{
    position: absolute;
    top:55%;
    color: #fff;
}

.loaderContainer {
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-70%, -50%);
    width: 4rem;
}

.firstSpinner {
    padding-left: 2.5rem;
}
.secondSpinner {
    margin-top: -1.25rem;
}
