.dateForm{
    width: fit-content;
    display: flex;
    justify-content: space-between;
}

.dateInput{
    margin-right: 10px;
}

.stickHeader {
    background: linear-gradient(135deg, #cedce7 10%,#e0f9f6 75%) !important;
}
.sticky {
    position: sticky;
    top: 0;
}