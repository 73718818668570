.topNavbar {
  /* width:100%; */
  display: flex;
  align-items: center;
  height: 80px;
  padding: 1% 1%;
  background: linear-gradient(
    135deg,
    #ffffff 20%,
    #f6f6f6 26%,
    #f6f6f6 26%,
    #f1f1f1 100%,
    #e8e8e8 100%
  );
  border-bottom: 1px solid rgba(31, 45, 61, 0.15);
  -webkit-filter: drop-shadow(2px 1px 2px rgba(0, 0, 0, 0.7));
  filter: drop-shadow(1px 1px 1px rgba(0, 0, 0, 0.7));
}

.logo {
  height: 2.5em;
  padding-left: 1%;
  cursor: pointer;
}

.logo img {
  padding: 1%;
  margin-top: -15px;
  max-width: 100%;
}

.navItems {
  display: flex;
  align-items: stretch;
  margin-left: 1%;
  width: 85%;
}

.navItems a {
  color: #000 !important;
  text-decoration: none;
  text-transform: uppercase;
}

.navItem {
  display: flex;
  align-items: center;
  height: 100%;
  margin-left: 1%;
  align-self: center;
  padding: 8px;
}

.navItem:hover {
  border-radius: 8px 8px 0 0;
  background-color: #c0c1c4;
  background-image: linear-gradient(to bottom, #c0c1c4, #f5f7fa);
}

.liveData {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100px;
}

.liveData h4 {
  margin: 0;
  padding: 0;
}

.liveDataLinks {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.liveDataLink {
  display: flex;
  justify-content: center;
  align-items: center;
  color: black;
  font-size: 0.8rem;
  font-weight: 600;
  padding: 2px 2px;
  border: solid 1px transparent;
  border-radius: 3px;
  cursor: pointer;
  background: radial-gradient(at 50% 100%, #a5a5a5, rgba(213, 213, 213, 0) 70%)
      left 50% bottom 0.125em/80% 0.125em no-repeat padding-box,
    radial-gradient(closest-side, #f5f5f5, rgba(213, 213, 213, 0)) 50% 100%/80%
      0.125em no-repeat padding-box,
    linear-gradient(#f8f8f8, #b5b5b5) padding-box,
    linear-gradient(#c4c4c4, #d2d2d2) border-box;
  transition: 0.2s ease-out;
}

.liveDataLink a {
  color: black !important;
  text-decoration: none;
}

.liveDataLink:hover,
.liveDataLink:focus {
  outline: none;
  filter: contrast(0.65);
}

.avatarHolder {
  /* border: 1px solid rgba(31, 45, 61, .15); */
  /* background: #e9eef7; 
      border-radius: 15px;*/
  display: flexbox;
  height: 2.1rem;
  padding-right: 1%;
  transition: 1s;
  width: fit-content !important;
  margin-left: 8px;
  float: right;
}

.avatar {
  border-radius: 15px;
  background: #fff;
  width: 2rem;
  height: 2rem;
  margin: 1px;
  transition: 0.5s;
}

.avatarLabel {
  padding-right: 6%;
  margin: auto;
  color: black;
  text-transform: capitalize;
  transition: 1s;
  width: fit-content;
  margin-left: 8px;
}

.dropdownContent {
  display: none;
  background: #e9eef7;
  z-index: 10000;
  position: absolute;
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
  top: 101%;
  width: 100%;
  min-width: 80px;
  transition: 1s;
}

.dropdownContent a {
  color: black;
  padding: 6px;
  text-decoration: none;
  display: block;
  text-align: left;
  text-align: center;
}

.dropdownContent a:hover {
  background: #d1d7e2;
}

.avatarHolder:hover .dropdownContent {
  transition: 0.5s;
  display: block;
}

.avatarHolder:hover .avatarLabel {
  display: none;
  transition: 0.5s;
}

.avatarHolder:hover {
  background: #e9eef7;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
  min-width: 150px;
  height: 2.5rem;
  -webkit-filter: drop-shadow(2px 1px 2px #e9eef7);
  filter: drop-shadow(1px 1px 1px #e9eef7);
  box-shadow: 1px -1px 3px gray;
  transition: 0.5s;
}

.avatarHolder:hover .avatar {
  margin: auto;
  margin-top: 4%;
  margin-left: 40%;
  transition: 0.000001s;
}

@media screen and (max-width: 1023px) and (min-width: 100px) {
  .logo {
    width: 50px;
    height: 40px;
    min-width: unset;
  }
}
