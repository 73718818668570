.detailHeader {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-around;
  margin-bottom: 0.3rem;
}

.headerItem {
  display: flex;
}

.headerValue {
  display: flex;
  margin: 0;
  width: 100%;
  margin-left: 1rem;
}

.parameterSpan {
  color: grey;
  margin-left: 5px;
}

.details {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  flex: 1 1 auto;
}

.parametersConatiner {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 75%;
  padding: 5px 15px;
}

.parameterItem,
.progressItem {
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: 1rem;
}

.progressLabel {
  width: 30%;
  justify-self: flex-end;
}

.progressLabel h4 {
  margin: 0;
}

.progressValue {
  width: 70%;
}

.parameterValue {
  color: grey;
  margin: 0;
}

.progressBarContainer {
  background-color: rgb(192, 192, 192);
  width: 100%;
  height: 10px;
  border-radius: 5px;
}

.progressBar {
  /* background-color: rgb(116, 194, 92); */
  color: white;
  /* padding: 1%; */
  height: 100%;
  text-align: right;
  font-size: 20px;
  border-radius: 5px;
}

.gaugeContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 25%;
}

.small {
  margin-top: 0;
  margin-bottom: 0;
}
