.positionCenter {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.noDataIcon {
  font-size: 2.5rem;
}

.description {
  color: black !important;
  font-size: 1.2rem;
}
