.scrollBtn {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  right: 20px;
  bottom: 55px;
  width: 35px;
  height: 35px;
  font-size: 1.5rem;
  z-index: 1;
  cursor: pointer;
  color: black;
  padding: 3px;
  /* background-color: rgb(231, 180, 180); */
  background:radial-gradient(at 50% 100%, #a5a5a5, rgba(213, 213, 213, 0) 70%)
  left 50% bottom 0.125em/80% 0.125em no-repeat padding-box,
radial-gradient(closest-side, #f5f5f5, rgba(213, 213, 213, 0)) 50% 100%/80%
  0.125em no-repeat padding-box,
linear-gradient(#f8f8f8, #b5b5b5) padding-box,
linear-gradient(#c4c4c4, #d2d2d2) border-box;;
  border-radius: 50%;
}

.scrollHide{
    display: none;
}

.scrollBtn:hover{
    outline: none;
    filter: contrast(0.65);
}
