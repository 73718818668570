.p-0 {
  padding: 0;
}

.m-0 {
  margin: 0;
}

body {
  background-color: #f9f9f9;
}

.hr{
  border-image :linear-gradient(to left, rgba(0, 0, 0, 0) ,black 60%, rgba(0, 0, 0, 0)) 100% 0;
  border-bottom-width: 0;
  border-top-width: 1.5px;
}
:root {
  scrollbar-color: grey #e4e4e4 !important;
  scrollbar-width: thin !important;
}
body::-webkit-scrollbar {
  width: 8px;
}
body::-webkit-scrollbar-track {
  background-color: #e4e4e4;
  border-radius: 5px;
}
body::-webkit-scrollbar-thumb {
  background-color: grey;
}

/* alignments */
.text-center {
  text-align: center;
}

/* background colors */
.bg-light {
  background: linear-gradient(135deg, #cedce7 10%, #e0f9f6 75%) !important;
}
.bg-grey {
  background-color: #c6d1f3 !important;
}

.bg-danger {
  background-color: #dc3545 !important;
}

.bg-warning {
  background-color: #ffc107 !important;
}

.bg-info {
  background-color: #17a2b8 !important;
}

.bg-success {
  background-color: #28a745 !important;
}

.border-thin {
  border: 0.1px solid rgb(207, 205, 205);
}

.float-right {
  float: right;
}
.float-left {
  float: left;
}

/* containers and flex boxes */
.max-container {
  max-width: 1200px;
  margin: auto;
  box-sizing: border-box;
}
.lg-show {
    display: initial;
}
.md-show {
    display: none;
}
.md-center {
    display: initial; 
    place-items: initial;
}  
.container {
  display: flex;
  /* //flex-direction: row; */
  justify-content: space-between;
  background-color: #ffffff;
  align-items: center;
  border-radius: 5px;
  padding: 10px;
}

.flex-container {
  display: flex;
  flex-wrap: wrap;
  align-content: space-between;
  width: 100%;
}

.display-flex {
  display: flex;
}

.flex-column {
  flex-direction: column;
}

.flex-row {
  flex-direction: row;
}

.flex-item-1 {
  width: 100%;
}
.flex-item-2 {
  width: calc(98% / 2);
  margin-right: 2%;
}
.flex-item-2:nth-child(2n + 0) {
  margin-right: 0%;
}
.flex-item-3 {
  width: calc(96% / 3);
  margin-right: 2%;
  margin-bottom: 2%;
}
.flex-item-3:nth-child(3n + 0) {
  margin-right: 0%;
}
.flex-item-4 {
  width: calc(94% / 4);
  margin-right: 2%;
  margin-bottom: 2%;
}
.flex-item-4:nth-child(4n + 0) {
  margin-right: 0%;
}

/* cards */
.card-container {
  background-color: #ffffff;
  align-items: center;
  border-radius: 5px;
}

.card-header {
  padding: 10px 20px;
  margin-bottom: 0;
  background-color: #fff;
  border-bottom: 2px solid #d8e8ff;
  border-radius: inherit;
  margin-bottom: 2px;
  display: flex;
}

.card-body {
  padding: 10px;
}

.card-footer {
  padding: 10px 20px;
  margin-bottom: 0;
  background-color: #fff;
  border-top: 2px solid #edf2f9;
  border-radius: inherit;
}

.card-header h3 {
  padding: 0;
  margin: 0;
  width: calc(100% - 30px);
}
.card-footer h4 {
  text-align: end;
  padding: 0;
  margin: 0;
}

/* buttons */
button {
  min-width: 100px;
  height: 36px;
  color: black;
  font-size: 14px;
  font-weight: 600;
  text-transform: uppercase;
  border: solid 1px transparent;
  border-radius: 6px;
  margin: 2px;
  padding: 0;
  /* padding-right: 1em; */
  transition: 0.2s ease-out;
  cursor: pointer;
  background: radial-gradient(at 50% 100%, #a5a5a5, rgba(213, 213, 213, 0) 70%)
      left 50% bottom 0.125em/80% 0.125em no-repeat padding-box,
    radial-gradient(closest-side, #f5f5f5, rgba(213, 213, 213, 0)) 50% 100%/80%
      0.125em no-repeat padding-box,
    linear-gradient(#f8f8f8, #b5b5b5) padding-box,
    linear-gradient(#c4c4c4, #d2d2d2) border-box;
}

button:hover,
button:focus {
  outline: none;
  filter: contrast(0.65);
}

/* inputs and select */
input,
select {
  height: 30px;
  margin: 2px;
  min-width: 150px;
  border-radius: 6px;
}

.cam-icons {
  display: none;
  font-size: x-large;
}
.cam1-icons {
  font-size: x-large;
}

.cam1-icons i:hover {
  transform: scale(1.25);
}
.cam-icons i:hover {
  transform: scale(1.25);
}

.title-icons {
  margin-left: 10px;
  font-size: large;
}

/* table row colors */

.tr-1 {
  /* background-color: #FFE082; */
  background: linear-gradient(
    135deg,
    #fceabb 0%,
    #fccd4d 31%,
    #f7c442 66%,
    #fbdf93 100%
  );
}
.tr-2 {
  /* background-color: #42A5F5 ; */
  background: linear-gradient(135deg, #7abcff 7%, #4096ee 50%, #7abcff 94%);
}
.tr-3 {
  /* background-color: #81D4FA; */
  background: linear-gradient(135deg, #b4e391 7%, #2fbf18 50%, #b4e391 94%);
}

/* paddings */
.pd-0 {
  padding: 0;
}

/* margins */
.mt-24 {
  margin-top: 24px;
}
.mt-5 {
  margin-top: 5px;
}
.ml-10 {
  margin-left: 10px;
}
.ml-50 {
  margin-left: 50px;
}
.mr-10 {
  margin-right: 10px;
}
.mb-0 {
  margin-bottom: 0 !important;
}
.m-0 {
  margin: 0px;
}

/* borders, shadow and dots */
.border-red{
  border-left: 3px solid #ff3c00 !important;
}
 
.border-blue {
  border-left: 3px solid #4e73df !important;
}

.shadow {
  /* border: 1px solid #e6e6e6; */
  /* -webkit-filter: drop-shadow( 2px 1px 2px rgba(0, 0, 0, .7));
    filter: drop-shadow( 1px 1px 1px rgba(0, 0, 0, .7)); */
  box-shadow: 2px 1px 2px rgba(0, 0, 0, 0.2), 2px 1px 2px rgba(0, 0, 0, 0.2);
}

.dot {
  height: 8px;
  width: 8px;
  border-radius: 50%;
  display: inline-block;
}
/*color variants  */
.red {
  background-color: red;
}
.orangered {
  background-color: orangered;
}
.orange {
  background-color: orange;
}
.green {
  background-color: green;
}

/* media queries */
@media screen and (max-width: 1023px) and (min-width: 100px) {
    .flex-item-2,
    .flex-item-3,
    .flex-item-4 {
        width: 100%;
        margin: 2% auto;
        margin-bottom: 2%;
    }
    .max-container {
        max-width: calc(100% - 24px);
        margin: auto;
        box-sizing: border-box;
    }
    .lg-show {
        display: none;
    }
    .md-show {
        display: initial;
    }
    .md-center {
        display: grid; 
        place-items: center;
    }
}
/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) { /* ... */ }

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) and (max-width: 992px){ /* ... */ }

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) { /* ... */ }

/* Extra large devices (large desktops, 1200px and up)*/
@media (min-width: 1200px) { /* ... */ }
