.reactTable {
    width: 100%;
    /* overflow: auto; */
}

.reactTable tbody tr {
    background-color: #fff;
    border: 2px solid #000;
}

.reactTable thead tr {
    text-align: center;
}

.reactTable tbody tr:hover {
    background-color: rgb(245, 245, 245);
}

.reactTable thead tr:hover {
    background-color: inherit
}

.reactTable td {
    padding: 8px 8px;
    text-align: center;
    border-top: 1px solid black;
    min-width: '100px'
}

.reactTable th {
    padding: 8px 0;
    text-align: center;
}

.reactTable tr td:first-child {
    border-width: 1px;
    border-style: solid;
    border-image: linear-gradient(to left, black 80%, rgba(0, 0, 0, 0)) 100% 0;
    border-bottom: none;
}

.reactTable tr td:last-child {
    border-width: 1px;
    border-style: solid;
    border-image: linear-gradient(to right, black 80%, rgba(0, 0, 0, 0)) 100% 0;
    border-bottom: none;
}
