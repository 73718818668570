.cardContainer {
    background-color: #ffffff;
    border-radius: 5px;
    margin-top: 24px;
    border-left: 3px solid #4e73df !important;
    box-shadow: 2px 1px 2px rgba(0, 0, 0, 0.2), 2px 1px 2px rgba(0, 0, 0, 0.2);
}

.cardHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #fff;
    border-bottom: 2px solid #d8e8ff;
    padding: 10px 20px;
    margin-bottom: 0;
    background: linear-gradient(135deg, #cedce7 10%, #e0f9f6 75%) !important;
}

.cardHeader h3 {
    margin: 0;
    padding: 0;
}

.exportBtn {
    display: flex;
    justify-content: space-between;
    align-items: center;
    column-gap: 8px;
    text-decoration: none;
    border: 1px solid grey;
    border-radius: 4px;
    color: #000;
    padding: 8px 16px;
}

.exportBtn:hover {
    background-color: rgb(198, 198, 198);
}

.clearBtn {
    display: flex;
    justify-content: space-between;
    align-items: center;
    column-gap: 8px;
    text-decoration: none;
    border: 1px solid grey;
    border-radius: 4px;
    color: #000;
    padding: 4px 8px;
    cursor: pointer;
}
.clearBtn:hover {
    background-color: rgb(198, 198, 198);
}

.exportBtn p {
    margin: 0;
    padding: 0
}

.cardBody {
    padding: 10px;
}

.selectContainer {
    display: flex;
    column-gap: 24px;
    align-items: center;
    justify-content: space-between;
}

.selectBox {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    column-gap: 4px;
    /* align-items: center; */
}

.selectBox p{
   padding: 0;
}