.link-tag {
    color: #000;
    text-decoration: none;
  }
  
.ml-sm {
    margin-left: 6% !important;
}
.h4-margin {
    margin: .65em 0;
}
.ml-xs {
    margin-left: 2%;
}
.mr-md {
    margin-right: 30%;
}
hr {
    color: rgba(80, 70, 70, 0.2);
}
label {
    color: rgba(80, 70, 70, 1);
}

.mw-15 {
    min-width: 15px;
}
.mw-md {
    min-width: 4.2rem;
}
tr td {
    color: rgba(80, 70, 70, 1);
    font-size: 14px;
}
.bold {
    font-weight: bolder;
}

.gauge-item-2 {
    width: calc(98%/2);
    margin-right: 2%;
    margin-bottom: 2%;
}

.gauge-item-2:nth-child(2n+0) {
    margin-right: 0%;
}

.guage-container:hover {
    /* transform: scale(1.005); */
    background-color: rgb(100, 235, 235, 0.5);
}