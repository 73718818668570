@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap);
body {
  margin: 0;
  padding:0;
  box-sizing: border-box;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
    font-family:'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.Header_topNavbar__12NyK {
  /* width:100%; */
  display: flex;
  align-items: center;
  height: 80px;
  padding: 1% 1%;
  background: linear-gradient(
    135deg,
    #ffffff 20%,
    #f6f6f6 26%,
    #f6f6f6 26%,
    #f1f1f1 100%,
    #e8e8e8 100%
  );
  border-bottom: 1px solid rgba(31, 45, 61, 0.15);
  filter: drop-shadow(1px 1px 1px rgba(0, 0, 0, 0.7));
}

.Header_logo__A_KUQ {
  height: 2.5em;
  padding-left: 1%;
  cursor: pointer;
}

.Header_logo__A_KUQ img {
  padding: 1%;
  margin-top: -15px;
  max-width: 100%;
}

.Header_navItems__mjUOF {
  display: flex;
  align-items: stretch;
  margin-left: 1%;
  width: 85%;
}

.Header_navItems__mjUOF a {
  color: #000 !important;
  text-decoration: none;
  text-transform: uppercase;
}

.Header_navItem__1Y7OL {
  display: flex;
  align-items: center;
  height: 100%;
  margin-left: 1%;
  align-self: center;
  padding: 8px;
}

.Header_navItem__1Y7OL:hover {
  border-radius: 8px 8px 0 0;
  background-color: #c0c1c4;
  background-image: linear-gradient(to bottom, #c0c1c4, #f5f7fa);
}

.Header_liveData__D5ACJ {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100px;
}

.Header_liveData__D5ACJ h4 {
  margin: 0;
  padding: 0;
}

.Header_liveDataLinks__9Mk-D {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.Header_liveDataLink__1hzdZ {
  display: flex;
  justify-content: center;
  align-items: center;
  color: black;
  font-size: 0.8rem;
  font-weight: 600;
  padding: 2px 2px;
  border: solid 1px transparent;
  border-radius: 3px;
  cursor: pointer;
  background: radial-gradient(at 50% 100%, #a5a5a5, rgba(213, 213, 213, 0) 70%)
      left 50% bottom 0.125em/80% 0.125em no-repeat padding-box,
    radial-gradient(closest-side, #f5f5f5, rgba(213, 213, 213, 0)) 50% 100%/80%
      0.125em no-repeat padding-box,
    linear-gradient(#f8f8f8, #b5b5b5) padding-box,
    linear-gradient(#c4c4c4, #d2d2d2) border-box;
  transition: 0.2s ease-out;
}

.Header_liveDataLink__1hzdZ a {
  color: black !important;
  text-decoration: none;
}

.Header_liveDataLink__1hzdZ:hover,
.Header_liveDataLink__1hzdZ:focus {
  outline: none;
  filter: contrast(0.65);
}

.Header_avatarHolder__30ain {
  /* border: 1px solid rgba(31, 45, 61, .15); */
  /* background: #e9eef7; 
      border-radius: 15px;*/
  display: flexbox;
  height: 2.1rem;
  padding-right: 1%;
  transition: 1s;
  width: -webkit-fit-content !important;
  width: -moz-fit-content !important;
  width: fit-content !important;
  margin-left: 8px;
  float: right;
}

.Header_avatar__3ORFB {
  border-radius: 15px;
  background: #fff;
  width: 2rem;
  height: 2rem;
  margin: 1px;
  transition: 0.5s;
}

.Header_avatarLabel__1s5jS {
  padding-right: 6%;
  margin: auto;
  color: black;
  text-transform: capitalize;
  transition: 1s;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  margin-left: 8px;
}

.Header_dropdownContent__2j5Uy {
  display: none;
  background: #e9eef7;
  z-index: 10000;
  position: absolute;
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
  top: 101%;
  width: 100%;
  min-width: 80px;
  transition: 1s;
}

.Header_dropdownContent__2j5Uy a {
  color: black;
  padding: 6px;
  text-decoration: none;
  display: block;
  text-align: left;
  text-align: center;
}

.Header_dropdownContent__2j5Uy a:hover {
  background: #d1d7e2;
}

.Header_avatarHolder__30ain:hover .Header_dropdownContent__2j5Uy {
  transition: 0.5s;
  display: block;
}

.Header_avatarHolder__30ain:hover .Header_avatarLabel__1s5jS {
  display: none;
  transition: 0.5s;
}

.Header_avatarHolder__30ain:hover {
  background: #e9eef7;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
  min-width: 150px;
  height: 2.5rem;
  filter: drop-shadow(1px 1px 1px #e9eef7);
  box-shadow: 1px -1px 3px gray;
  transition: 0.5s;
}

.Header_avatarHolder__30ain:hover .Header_avatar__3ORFB {
  margin: auto;
  margin-top: 4%;
  margin-left: 40%;
  transition: 0.000001s;
}

@media screen and (max-width: 1023px) and (min-width: 100px) {
  .Header_logo__A_KUQ {
    width: 50px;
    height: 40px;
    min-width: unset;
  }
}

.Footer_footer__2vt1X {
  height: 1.2em;
  padding: 1% 1%;
  margin-bottom: 0;
  margin-top: 24px;
  bottom: 0;
  background: linear-gradient(
    135deg,
    #ffffff 0%,
    #f6f6f6 26%,
    #f6f6f6 26%,
    #f1f1f1 100%,
    #e8e8e8 100%
  );
  border-top: 1px solid rgba(31, 45, 61, 0.15);
  filter: drop-shadow(1px -1px 1px rgba(0, 0, 0, 0.7));
}

.Footer_footer__2vt1X p {
  max-width: 1200px;
  margin: auto;
}

@media screen and (max-width: 1023px) and (min-width: 100px) {
  .Footer_footer__2vt1X {
    height: 25px;
    font-size: smaller;
  }
}

.ScrollBtn_scrollBtn__3rMlt {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  right: 20px;
  bottom: 55px;
  width: 35px;
  height: 35px;
  font-size: 1.5rem;
  z-index: 1;
  cursor: pointer;
  color: black;
  padding: 3px;
  /* background-color: rgb(231, 180, 180); */
  background:radial-gradient(at 50% 100%, #a5a5a5, rgba(213, 213, 213, 0) 70%)
  left 50% bottom 0.125em/80% 0.125em no-repeat padding-box,
radial-gradient(closest-side, #f5f5f5, rgba(213, 213, 213, 0)) 50% 100%/80%
  0.125em no-repeat padding-box,
linear-gradient(#f8f8f8, #b5b5b5) padding-box,
linear-gradient(#c4c4c4, #d2d2d2) border-box;;
  border-radius: 50%;
}

.ScrollBtn_scrollHide__FHox2{
    display: none;
}

.ScrollBtn_scrollBtn__3rMlt:hover{
    outline: none;
    filter: contrast(0.65);
}

.ErrorComp_errorContainer__3sYtN {
  display: flex;
  background-color: #fae7e3;
  align-items: center;
  border-radius: 5px;
  margin-top: 5px !important;
}

.ErrorComp_errorContainer__3sYtN i {
  margin-left: 1.5rem;
  font-size: 1rem;
  color: red;
}

.ErrorComp_errorContainer__3sYtN h3 {
  padding: 0.5rem;
  margin: 0;
  font-size: 14px;
  font-weight: 400;
}

.ErrorComp_underline__XqNQu {
  height: 2px;
  margin: 0 5px;
  background-image: linear-gradient(to right, red, #f7c4c1, #f2948f, #f7584d);
  animation: ErrorComp_drawBorder__rkIAl 6s ease forwards;
}

@keyframes ErrorComp_drawBorder__rkIAl {
  from {
    max-width: 100%;
  }
  to {
    max-width: 0%;
    height: 1px;
  }
}

.MachineDescription_machineDes__2OtuM{
    position: relative;
    max-width: 1200px;
    display: flex;
    margin: auto;
    margin-top: 30px;
    margin-bottom: 20px;
    padding: 0.5% 1%;
    border-radius: 10px;
    border: 4px solid #cedce7;
    z-index: -1;
}

.MachineDescription_machineTobe__C-qDB{
    background: #fff; 
    padding: 5px;
    border-radius: 50%;
    border: 4px solid #cedce7;
    position: absolute;
    display:flex;
    align-items: center;
    top: -45%;
    left: -7%;
    animation: MachineDescription_spin__1SIO_ 10s linear infinite;
}

.MachineDescription_machineTobe__C-qDB img{
    width: 85px;
}

.MachineDescription_content__1Oy6a{
    width: 100%;
    max-width: 1200px;
    margin: auto;
    display:flex;
    margin-left: 8px;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.MachineDescription_machineFeatures__3R1gY{
    width: 100%;
    display:flex;
    align-items: center;
    margin: 0 ;
}

.MachineDescription_machineFeatureItem__3A7f8{
    display:flex;
    align-items: center;
    margin-left: 28px;
}

.MachineDescription_machineFeatureItem__3A7f8 i{
    display:flex;
    color: orangered;
    font-size: 1.5rem;
    margin-right: 10px;
}

.MachineDescription_machineFeatures__3R1gY h5{
    margin: 0 ;

}

.Layout_page__2ZDJG{
    width:100%;
    /* background-color: #f9f9f9; */
}

.BackDrop_backdrop__1llpQ {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 10;
  background: rgba(0, 0, 0, 0.65);
}

.Loader_backDrop__3o8xN{
    display: flex;
    justify-content:center ;
    align-items: center;
    width:100vh;
    height: 100vh;
}

.Loader_loaderTitle__3IWIX{
    position: absolute;
    top:55%;
    color: #fff;
}

.Loader_loaderContainer__1Cczt {
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-70%, -50%);
    width: 4rem;
}

.Loader_firstSpinner__1Knh3 {
    padding-left: 2.5rem;
}
.Loader_secondSpinner__2P1OH {
    margin-top: -1.25rem;
}

.InputHeader_dateForm__3oFI0{
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    display: flex;
    justify-content: space-between;
}

.InputHeader_dateInput__3F6fi{
    margin-right: 10px;
}

.InputHeader_stickHeader__3HTdG {
    background: linear-gradient(135deg, #cedce7 10%,#e0f9f6 75%) !important;
}
.InputHeader_sticky__1Dk1s {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
}
.RadialGauge_gaugeItem__2uEp- {
  width: calc(98% / 2);
  margin-right: 2%;
  margin-bottom: 2%;
}

.RadialGauge_gaugeItem__2uEp-:nth-child(2n + 0) {
  margin-right: 0%;
}

.RadialGauge_svgBox__1sWLr {
  display: block;
  width: 160px;
  height: 160px;
  margin: 3px;
}
@keyframes RadialGauge_fadeIn__1RNOC {
  to {
    opacity: 1;
  }
}

.RadialGauge_svgBox__1sWLr circle {
  fill: none;
  stroke-width: 12px;
  margin: 3px;
  transform-origin: 75px 70px 0;
  transform: rotate(270deg);
  animation-delay: 1s;
  animation: RadialGauge_fadeIn__1RNOC 0.75s linear forwards;
}

.RadialGauge_svgBox__1sWLr circle:nth-child(3) {
  stroke: rgba(80, 70, 70, 0.2);
}

.RadialGauge_svgBox__1sWLr circle:nth-child(2) {
  stroke: #7fc146;
  stroke-dasharray: 402.124;
  stroke-dashoffset: 402.124;
  transition: all 2s ease-out;
  animation-delay: 1s;
  animation: RadialGauge_fadeIn__1RNOC 0.75s linear forwards;
}

.RadialGauge_svgBox__1sWLr circle:nth-child(2) {
  stroke: url(#MyGradient);
}

.RadialGauge_svgBox__1sWLr text {
  fill: black;
}
.RadialGauge_svgBoxText__3Aca3 {
  font-size: 30px;
  font-weight: 300;
}
.RadialGauge_svgBox__1sWLr text.RadialGauge_caption__21kzn {
  font-weight: bold;
  font-size: 15px;
  text-transform: uppercase;
}

.ml-sm {
    margin-left: 6% !important;
}

.h4-margin {
    margin: .65em 0;
}

.ml-xs {
    margin-left: 2%;
}

.mr-md {
    margin-right: 30%;
}

hr {
    color: rgba(80, 70, 70, 0.2);
}

label {
    color: rgba(80, 70, 70, 1);
}

.mw-15 {
    min-width: 15px;
}

.mw-md {
    min-width: 4.2rem;
}

tr td {
    color: rgba(80, 70, 70, 1);
    font-size: 14px;
}

.bold {
    font-weight: bolder;
}

.dot1 {
    display: inline-block;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    margin-left: 10px;
    position: relative;
    background-color: green;
    transform: scale(0.4);
}

.dot1::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: inherit;
    border-radius: 50%;
    animation: ripple 1.5s ease-out infinite;
    animation-delay: 1s;
}

@keyframes ripple {
    from {
        opacity: 1;
        transform: scale(0);
    }

    to {
        opacity: 0;
        transform: scale(3);
    }
}
.DashBoardAnalytics_analyticsContainer__1kK7W {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
   
    align-items: center;
    border-radius: 4px;
    margin-top: 24px;
   
}

.DashBoardAnalytics_gaugeContainer__1vv7g {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.DashBoardAnalytics_gaugeTitle__3NJUP {
    margin-top: 0;
    margin-bottom: 0;
}

.DashBoardAnalytics_progressLabel__20nOW {
    width: 100%;
  }
  
  .DashBoardAnalytics_label____jVa h5 {
    margin: 0;
  }
  
  .DashBoardAnalytics_progressValue__3nNSS {
    width: 70%;
  }
  
  .DashBoardAnalytics_parameterValue__29P6p {
    color: grey;
    margin: 0;
  }

.Pagination_root__3uBS6 {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    border-top: 1px solid #000;
    padding: 8px 20px;
}

.Pagination_mainContainer__3za2I {
    display: flex;
    margin-left: 10px;
    align-items: center;
}

.Pagination_btnContainer__14Gd4 {
    display: flex;
    margin-left: 5px;
}

.Pagination_btn__3HtHE {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: inherit;
    border: solid 0.5px #cecece;
    padding: 8px;
    border-radius: 4px
}

.Pagination_btn__3HtHE:hover {
    background-color: #cecece;
    border: solid 0.5px #cecece;
    border-radius: 4px
}
.ReactTable_reactTable__3A8X2 {
    width: 100%;
    /* overflow: auto; */
}

.ReactTable_reactTable__3A8X2 tbody tr {
    background-color: #fff;
    border: 2px solid #000;
}

.ReactTable_reactTable__3A8X2 thead tr {
    text-align: center;
}

.ReactTable_reactTable__3A8X2 tbody tr:hover {
    background-color: rgb(245, 245, 245);
}

.ReactTable_reactTable__3A8X2 thead tr:hover {
    background-color: inherit
}

.ReactTable_reactTable__3A8X2 td {
    padding: 8px 8px;
    text-align: center;
    border-top: 1px solid black;
    min-width: '100px'
}

.ReactTable_reactTable__3A8X2 th {
    padding: 8px 0;
    text-align: center;
}

.ReactTable_reactTable__3A8X2 tr td:first-child {
    border-width: 1px;
    border-style: solid;
    border-image: linear-gradient(to left, black 80%, rgba(0, 0, 0, 0)) 100% 0;
    border-bottom: none;
}

.ReactTable_reactTable__3A8X2 tr td:last-child {
    border-width: 1px;
    border-style: solid;
    border-image: linear-gradient(to right, black 80%, rgba(0, 0, 0, 0)) 100% 0;
    border-bottom: none;
}

.ProductionTable_cardContainer__1ZwT5 {
    background-color: #ffffff;
    border-radius: 5px;
    margin-top: 24px;
    border-left: 3px solid #4e73df !important;
    box-shadow: 2px 1px 2px rgba(0, 0, 0, 0.2), 2px 1px 2px rgba(0, 0, 0, 0.2);
}

.ProductionTable_cardHeader__39KYI {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #fff;
    border-bottom: 2px solid #d8e8ff;
    padding: 10px 20px;
    margin-bottom: 0;
    background: linear-gradient(135deg, #cedce7 10%, #e0f9f6 75%) !important;
}

.ProductionTable_cardHeader__39KYI h3 {
    margin: 0;
    padding: 0;
}

.ProductionTable_exportBtn__1cVdP {
    display: flex;
    justify-content: space-between;
    align-items: center;
    grid-column-gap: 8px;
    column-gap: 8px;
    text-decoration: none;
    border: 1px solid grey;
    border-radius: 4px;
    color: #000;
    padding: 8px 16px;
}

.ProductionTable_exportBtn__1cVdP:hover {
    background-color: rgb(198, 198, 198);
}

.ProductionTable_clearBtn__1_zdQ {
    display: flex;
    justify-content: space-between;
    align-items: center;
    grid-column-gap: 8px;
    column-gap: 8px;
    text-decoration: none;
    border: 1px solid grey;
    border-radius: 4px;
    color: #000;
    padding: 4px 8px;
    cursor: pointer;
}
.ProductionTable_clearBtn__1_zdQ:hover {
    background-color: rgb(198, 198, 198);
}

.ProductionTable_exportBtn__1cVdP p {
    margin: 0;
    padding: 0
}

.ProductionTable_cardBody__28b4r {
    padding: 10px;
}

.ProductionTable_selectContainer__2rY4O {
    display: flex;
    grid-column-gap: 24px;
    column-gap: 24px;
    align-items: center;
    justify-content: space-between;
}

.ProductionTable_selectBox__142bz {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    grid-column-gap: 4px;
    column-gap: 4px;
    /* align-items: center; */
}

.ProductionTable_selectBox__142bz p{
   padding: 0;
}
.link-tag {
    color: #000;
    text-decoration: none;
  }
  
.ml-sm {
    margin-left: 6% !important;
}
.h4-margin {
    margin: .65em 0;
}
.ml-xs {
    margin-left: 2%;
}
.mr-md {
    margin-right: 30%;
}
hr {
    color: rgba(80, 70, 70, 0.2);
}
label {
    color: rgba(80, 70, 70, 1);
}

.mw-15 {
    min-width: 15px;
}
.mw-md {
    min-width: 4.2rem;
}
tr td {
    color: rgba(80, 70, 70, 1);
    font-size: 14px;
}
.bold {
    font-weight: bolder;
}

.gauge-item-2 {
    width: calc(98%/2);
    margin-right: 2%;
    margin-bottom: 2%;
}

.gauge-item-2:nth-child(2n+0) {
    margin-right: 0%;
}

.guage-container:hover {
    /* transform: scale(1.005); */
    background-color: rgb(100, 235, 235, 0.5);
}
.NoData_positionCenter__3EciD {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.NoData_noDataIcon__32ehe {
  font-size: 2.5rem;
}

.NoData_description__3Jn1d {
  color: black !important;
  font-size: 1.2rem;
}

.OperatorDetails_detailHeader__3KERe {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-around;
  margin-bottom: 0.3rem;
}

.OperatorDetails_headerItem__32iVn {
  display: flex;
}

.OperatorDetails_headerValue__PXXU2 {
  display: flex;
  margin: 0;
  width: 100%;
  margin-left: 1rem;
}

.OperatorDetails_parameterSpan__2kpKP {
  color: grey;
  margin-left: 5px;
}

.OperatorDetails_details__1vInG {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  flex: 1 1 auto;
}

.OperatorDetails_parametersConatiner__3LyqD {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 75%;
  padding: 5px 15px;
}

.OperatorDetails_parameterItem__Fd-yX,
.OperatorDetails_progressItem__29e6a {
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: 1rem;
}

.OperatorDetails_progressLabel__24CqK {
  width: 30%;
  justify-self: flex-end;
}

.OperatorDetails_progressLabel__24CqK h4 {
  margin: 0;
}

.OperatorDetails_progressValue__3ps7y {
  width: 70%;
}

.OperatorDetails_parameterValue__2PZNg {
  color: grey;
  margin: 0;
}

.OperatorDetails_progressBarContainer__1c66A {
  background-color: rgb(192, 192, 192);
  width: 100%;
  height: 10px;
  border-radius: 5px;
}

.OperatorDetails_progressBar__3CBvR {
  /* background-color: rgb(116, 194, 92); */
  color: white;
  /* padding: 1%; */
  height: 100%;
  text-align: right;
  font-size: 20px;
  border-radius: 5px;
}

.OperatorDetails_gaugeContainer__3VngS {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 25%;
}

.OperatorDetails_small__1wxWo {
  margin-top: 0;
  margin-bottom: 0;
}

.NotFound_notFound__3_hJ7{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    min-height: 80vh;
    height: 100%;
    width: 100%;
}

.NotFound_notFound_icon__1jEWp {
    font-size: 8rem
}
.p-0 {
  padding: 0;
}

.m-0 {
  margin: 0;
}

body {
  background-color: #f9f9f9;
}

.hr{
  border-image :linear-gradient(to left, rgba(0, 0, 0, 0) ,black 60%, rgba(0, 0, 0, 0)) 100% 0;
  border-bottom-width: 0;
  border-top-width: 1.5px;
}
:root {
  scrollbar-color: grey #e4e4e4 !important;
  scrollbar-width: thin !important;
}
body::-webkit-scrollbar {
  width: 8px;
}
body::-webkit-scrollbar-track {
  background-color: #e4e4e4;
  border-radius: 5px;
}
body::-webkit-scrollbar-thumb {
  background-color: grey;
}

/* alignments */
.text-center {
  text-align: center;
}

/* background colors */
.bg-light {
  background: linear-gradient(135deg, #cedce7 10%, #e0f9f6 75%) !important;
}
.bg-grey {
  background-color: #c6d1f3 !important;
}

.bg-danger {
  background-color: #dc3545 !important;
}

.bg-warning {
  background-color: #ffc107 !important;
}

.bg-info {
  background-color: #17a2b8 !important;
}

.bg-success {
  background-color: #28a745 !important;
}

.border-thin {
  border: 0.1px solid rgb(207, 205, 205);
}

.float-right {
  float: right;
}
.float-left {
  float: left;
}

/* containers and flex boxes */
.max-container {
  max-width: 1200px;
  margin: auto;
  box-sizing: border-box;
}
.lg-show {
    display: initial;
}
.md-show {
    display: none;
}
.md-center {
    display: initial; 
    place-items: initial;
}  
.container {
  display: flex;
  /* //flex-direction: row; */
  justify-content: space-between;
  background-color: #ffffff;
  align-items: center;
  border-radius: 5px;
  padding: 10px;
}

.flex-container {
  display: flex;
  flex-wrap: wrap;
  align-content: space-between;
  width: 100%;
}

.display-flex {
  display: flex;
}

.flex-column {
  flex-direction: column;
}

.flex-row {
  flex-direction: row;
}

.flex-item-1 {
  width: 100%;
}
.flex-item-2 {
  width: calc(98% / 2);
  margin-right: 2%;
}
.flex-item-2:nth-child(2n + 0) {
  margin-right: 0%;
}
.flex-item-3 {
  width: calc(96% / 3);
  margin-right: 2%;
  margin-bottom: 2%;
}
.flex-item-3:nth-child(3n + 0) {
  margin-right: 0%;
}
.flex-item-4 {
  width: calc(94% / 4);
  margin-right: 2%;
  margin-bottom: 2%;
}
.flex-item-4:nth-child(4n + 0) {
  margin-right: 0%;
}

/* cards */
.card-container {
  background-color: #ffffff;
  align-items: center;
  border-radius: 5px;
}

.card-header {
  padding: 10px 20px;
  margin-bottom: 0;
  background-color: #fff;
  border-bottom: 2px solid #d8e8ff;
  border-radius: inherit;
  margin-bottom: 2px;
  display: flex;
}

.card-body {
  padding: 10px;
}

.card-footer {
  padding: 10px 20px;
  margin-bottom: 0;
  background-color: #fff;
  border-top: 2px solid #edf2f9;
  border-radius: inherit;
}

.card-header h3 {
  padding: 0;
  margin: 0;
  width: calc(100% - 30px);
}
.card-footer h4 {
  text-align: end;
  padding: 0;
  margin: 0;
}

/* buttons */
button {
  min-width: 100px;
  height: 36px;
  color: black;
  font-size: 14px;
  font-weight: 600;
  text-transform: uppercase;
  border: solid 1px transparent;
  border-radius: 6px;
  margin: 2px;
  padding: 0;
  /* padding-right: 1em; */
  transition: 0.2s ease-out;
  cursor: pointer;
  background: radial-gradient(at 50% 100%, #a5a5a5, rgba(213, 213, 213, 0) 70%)
      left 50% bottom 0.125em/80% 0.125em no-repeat padding-box,
    radial-gradient(closest-side, #f5f5f5, rgba(213, 213, 213, 0)) 50% 100%/80%
      0.125em no-repeat padding-box,
    linear-gradient(#f8f8f8, #b5b5b5) padding-box,
    linear-gradient(#c4c4c4, #d2d2d2) border-box;
}

button:hover,
button:focus {
  outline: none;
  filter: contrast(0.65);
}

/* inputs and select */
input,
select {
  height: 30px;
  margin: 2px;
  min-width: 150px;
  border-radius: 6px;
}

.cam-icons {
  display: none;
  font-size: x-large;
}
.cam1-icons {
  font-size: x-large;
}

.cam1-icons i:hover {
  transform: scale(1.25);
}
.cam-icons i:hover {
  transform: scale(1.25);
}

.title-icons {
  margin-left: 10px;
  font-size: large;
}

/* table row colors */

.tr-1 {
  /* background-color: #FFE082; */
  background: linear-gradient(
    135deg,
    #fceabb 0%,
    #fccd4d 31%,
    #f7c442 66%,
    #fbdf93 100%
  );
}
.tr-2 {
  /* background-color: #42A5F5 ; */
  background: linear-gradient(135deg, #7abcff 7%, #4096ee 50%, #7abcff 94%);
}
.tr-3 {
  /* background-color: #81D4FA; */
  background: linear-gradient(135deg, #b4e391 7%, #2fbf18 50%, #b4e391 94%);
}

/* paddings */
.pd-0 {
  padding: 0;
}

/* margins */
.mt-24 {
  margin-top: 24px;
}
.mt-5 {
  margin-top: 5px;
}
.ml-10 {
  margin-left: 10px;
}
.ml-50 {
  margin-left: 50px;
}
.mr-10 {
  margin-right: 10px;
}
.mb-0 {
  margin-bottom: 0 !important;
}
.m-0 {
  margin: 0px;
}

/* borders, shadow and dots */
.border-red{
  border-left: 3px solid #ff3c00 !important;
}
 
.border-blue {
  border-left: 3px solid #4e73df !important;
}

.shadow {
  /* border: 1px solid #e6e6e6; */
  /* -webkit-filter: drop-shadow( 2px 1px 2px rgba(0, 0, 0, .7));
    filter: drop-shadow( 1px 1px 1px rgba(0, 0, 0, .7)); */
  box-shadow: 2px 1px 2px rgba(0, 0, 0, 0.2), 2px 1px 2px rgba(0, 0, 0, 0.2);
}

.dot {
  height: 8px;
  width: 8px;
  border-radius: 50%;
  display: inline-block;
}
/*color variants  */
.red {
  background-color: red;
}
.orangered {
  background-color: orangered;
}
.orange {
  background-color: orange;
}
.green {
  background-color: green;
}

/* media queries */
@media screen and (max-width: 1023px) and (min-width: 100px) {
    .flex-item-2,
    .flex-item-3,
    .flex-item-4 {
        width: 100%;
        margin: 2% auto;
        margin-bottom: 2%;
    }
    .max-container {
        max-width: calc(100% - 24px);
        margin: auto;
        box-sizing: border-box;
    }
    .lg-show {
        display: none;
    }
    .md-show {
        display: initial;
    }
    .md-center {
        display: grid; 
        place-items: center;
    }
}
/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) { /* ... */ }

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) and (max-width: 992px){ /* ... */ }

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) { /* ... */ }

/* Extra large devices (large desktops, 1200px and up)*/
@media (min-width: 1200px) { /* ... */ }

