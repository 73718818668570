.errorContainer {
  display: flex;
  background-color: #fae7e3;
  align-items: center;
  border-radius: 5px;
  margin-top: 5px !important;
}

.errorContainer i {
  margin-left: 1.5rem;
  font-size: 1rem;
  color: red;
}

.errorContainer h3 {
  padding: 0.5rem;
  margin: 0;
  font-size: 14px;
  font-weight: 400;
}

.underline {
  height: 2px;
  margin: 0 5px;
  background-image: linear-gradient(to right, red, #f7c4c1, #f2948f, #f7584d);
  animation: drawBorder 6s ease forwards;
}

@keyframes drawBorder {
  from {
    max-width: 100%;
  }
  to {
    max-width: 0%;
    height: 1px;
  }
}
